














































































































import moment from "moment";
import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Result, ResultActions, ResultGetters, AnswerVotes } from "./types";

@Component({})
export default class PollResults extends Vue {
  @Action(ResultActions.fetchPollResults)
  fetchPollResults!: () => Promise<void>;
  @Getter(ResultGetters.Results)
  readonly getResults!: Result[];

  expanded = [];
  search = "";
  loading = false;

  refreshIntervalId: number | null = null;

  get results() {
    return this.getResults.map((r) => ({
      ...r,
      komponistenSummary: `${r.komponistenLiveVotePresenceInPerson ?? "–"} / ${
        r.komponistenLiveVotePresenceDigital ?? "–"
      }`,
      textdichterSummary: `${r.textdichterLiveVotePresenceInPerson ?? "–"} / ${
        r.textdichterLiveVotePresenceDigital ?? "–"
      }`,
      verlegerSummary: `${r.verlegerLiveVotePresenceInPerson ?? "–"} / ${
        r.verlegerLiveVotePresenceDigital ?? "–"
      }`,
      allSummary: `${r.allLiveVotesPresenceInPerson ?? "–"} / ${
        r.allLiveVotesPresenceDigital ?? "–"
      }`,
    }));
  }

  get pollHeaders() {
    return [
      {
        text: "Titel",
        value: "title",
        divider: true,
      },
      {
        text: "Geschlossen",
        value: "closedAt",
        filterable: false,
        divider: true,
      },
      {
        text: "",
        value: "presentVoters",
        filterable: false,
        sortable: false,
        divider: true,
      },
      {
        text: "Komponisten",
        value: "komponistenSummary",
        align: "center",
        filterable: false,
        sortable: false,
        divider: true,
      },
      {
        text: "Textdichter",
        value: "textdichterSummary",
        align: "center",
        filterable: false,
        sortable: false,
        divider: true,
      },
      {
        text: "Verleger",
        value: "verlegerSummary",
        align: "center",
        filterable: false,
        sortable: false,
        divider: true,
      },
      {
        text: "Alle",
        value: "allSummary",
        align: "center",
        filterable: false,
        sortable: false,
        divider: true,
      },
      {
        text: "",
        value: "data-table-expand",
        filterable: false,
        sortable: false,
      },
    ];
  }

  komponistenVotes(answerVotes: AnswerVotes): string {
    return `${answerVotes.komponistenPreVote ?? "–"} /
            ${answerVotes.komponistenLiveVote ?? "–"} /
            ${answerVotes.komponistenTotal ?? "–"}`;
  }

  textdichterVotes(answerVotes: AnswerVotes): string {
    return `${answerVotes.textdichterPreVote ?? "–"} /
            ${answerVotes.textdichterLiveVote ?? "–"} /
            ${answerVotes.textdichterTotal ?? "–"}`;
  }

  verlegerVotes(answerVotes: AnswerVotes): string {
    return `${answerVotes.verlegerPreVote ?? "–"} /
            ${answerVotes.verlegerLiveVote ?? "–"} /
            ${answerVotes.verlegerTotal ?? "–"}`;
  }

  allVotes(answerVotes: AnswerVotes): string {
    return `${answerVotes.allPreVotes ?? "–"} /
            ${answerVotes.allLiveVotes ?? "–"} /
            ${answerVotes.allTotalVotes ?? "–"}`;
  }

  async created(): Promise<void> {
    await this.refreshResults();
    // delay = 3min
    this.refreshIntervalId = setInterval(() => {
      this.refreshResults();
    }, 180000);
  }

  destroyed(): void {
    if (this.refreshIntervalId) {
      clearInterval(this.refreshIntervalId);
    }
  }

  getDate(date: string) {
    return date ? moment(date).format("DD.MM.YYYY") : null;
  }

  async refreshResults() {
    if (!this.loading) {
      this.loading = true;
      await this.fetchPollResults();
      this.loading = false;
    }
  }
}
