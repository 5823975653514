




import PollResults from "@/components/PollResults/PollResults.vue";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PollResults,
  },
})
export default class PollResultsView extends Vue {}
