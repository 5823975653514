var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"results-table",staticStyle:{"width":"100%"},attrs:{"footer-props":{
    'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
  },"headers":_vm.pollHeaders,"items":_vm.results,"search":_vm.search,"item-key":"responseId","expanded":_vm.expanded,"show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header-search"},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("polls.header"))+" ("+_vm._s(_vm.results.length)+") ")]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-0 pa-2 mr-5 background--text",attrs:{"single-line":"","hide-details":"","color":"brand","loading":_vm.loading},on:{"click":_vm.refreshResults}},[_vm._v(_vm._s(_vm.$t("polls.refreshResult")))]),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-magnify","placeholder":_vm.$t('forms.search'),"single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
  var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0"},attrs:{"colspan":_vm.pollHeaders.length}},[_c('v-simple-table',{staticClass:"expand-table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('colgroup',[_c('col'),_c('col'),_c('col'),_c('col'),_c('col'),_c('col'),_c('col'),_c('col')]),_c('tbody',_vm._l((item.answerVotes),function(answerVotes,index){return _c('tr',{key:index,staticStyle:{"width":"100%"}},[_c('td',{staticClass:"divider"}),_c('td',{staticClass:"divider"}),_c('td',{staticClass:"divider"},[_vm._v(" "+_vm._s(answerVotes.value)+" ")]),_c('td',{staticClass:"text-center divider"},[_vm._v(" "+_vm._s(_vm.komponistenVotes(answerVotes))+" ")]),_c('td',{staticClass:"text-center divider"},[_vm._v(" "+_vm._s(_vm.textdichterVotes(answerVotes))+" ")]),_c('td',{staticClass:"text-center divider"},[_vm._v(" "+_vm._s(_vm.verlegerVotes(answerVotes))+" ")]),_c('td',{staticClass:"text-center divider"},[_vm._v(" "+_vm._s(_vm.allVotes(answerVotes))+" ")]),_c('td')])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.title",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.closedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.closedAt))+" ")]}},{key:"item.presentVoters",fn:function(){return [_vm._v(" Anwesende Stimmrechte ")]},proxy:true},{key:"item.komponistenTotal",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.komponistenTotalPresence)+" ")]}},{key:"item.textdichterTotal",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.komponistenTotalPresence)+" ")]}},{key:"item.verlegerTotal",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.verlegerTotalPresence)+" ")]}},{key:"item.allTotalVotes",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.allTotalVotesPresence)+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }